import React from "react";
import Project from "./Project";

const Projects = ({}) => {

    let projects = [
        {
            title: 'Studio++',
            description: 'A full-service multidisciplinary design studio focusing on artificial intelligence.',
            year: '2021 - Present',
            location: 'Munich, Germany',
            website: 'studio-plus.plus',
            tags: ['founder'],
            featured: false
        },
        {
            title: 'FRESH TRASH',
            description: 'A modern Berlin fashion label producing and selling upcycled fashion and acts as a platform for emerging designers.',
            year: '2021 - Present',
            location: 'Berlin, Germany',
            website: 'freshtrash.de',
            tags: ['co-founder'],
            featured: false
        },
        {
            title: 'Markt der Zukunft',
            description: 'The Markt der Zukunft takes on a hub function for actors of sustainable change and develops an action and communication space for the exchange of experiences, ideas, concepts and practices.',
            year: '2022 - Present',
            location: 'Graz, Austria',
            website: 'marktderzukunft.at',
            tags: ['advisor', 'developer'],
            featured: false
        },
        {
            title: 'Climate Festival',
            description: 'The Clim@ Festival is a one day event which revolves around climate change organised by Die Grünen Steiermark.',
            year: '2022',
            location: 'Graz, Austria',
            website: 'climatefestival.at',
            tags: ['developer'],
            featured: false
        },
        {
            title: 'BXN CLUB',
            description: 'A sports club dedicated to the passion of martial arts.',
            year: '2021 - Present',
            location: 'Graz, Austria',
            website: 'bxn-graz.club',
            tags: ['co-founder', 'director'],
            image: 'bxn.jpg',
            video: '758656434',
            featured: true
        },
        {
            title: 'Fleur de Nuit',
            description: 'Website for a wedding band based in Styria.',
            year: '2022',
            location: 'Graz, Austria',
            website: 'fleurdenuit.at',
            tags: ['designer', 'developer'],
            featured: false
        },
        {
            title: 'Matthey & Melchior',
            description: 'Matthey & Melchior is a small publisher for independent books based in Graz.',
            year: '2022',
            location: 'Graz, Austria',
            website: 'mattheymelchior.at',
            tags: ['designer', 'developer'],
            featured: false
        },
        {
            title: 'Fluid',
            description: 'Webdesign based on user preference and device data.',
            year: '2019',
            location: 'World Wide Web',
            website: 'fluid.paulpetritsch.fyi',
            tags: ['creator'],
            featured: false
        },
        {
            title: 'Re-Jig',
            description: 're-jig provides a platform for everyone to create their own festival. It consists of a modular event kit paired with a team of designers to materialise every vision.',
            year: '2018',
            location: 'London, United Kingdom',
            website: 'rejig.paulpetritsch.fyi',
            tags: ['co-founder'],
            featured: false
        },
        {
            title: 'United.Strong',
            description: 'Online poster creator, where you can create a message with regards to the quarantine in 2020 and how to survive in order to help anyone who is going through a hard time.',
            year: '2020',
            location: 'World Wide Web',
            website: 'united-strong.paulpetritsch.fyi',
            tags: ['creator'],
            featured: false
        },
        {
            title: 'nudesign',
            description: 'nudesign is a design studio focusing on interactive services.',
            year: '2020',
            location: 'Graz, Austria',
            website: 'nudesign.paulpetritsch.fyi',
            tags: ['co-founder'],
            featured: false
        },
    ]

    return (
        <>
            <div className="projects__outer" id={"work"}>
                <div className="projects__inner">
                    {projects.map(el => {
                        return <Project project={el}></Project>
                    })}
                </div>
            </div>
        </>
    )
}


export default Projects

