import React from "react"
import * as THREE from "three"
import DeviceOrientationControls from "three-device-orientation";

let mouse, INTERSECTED;

class Scene extends React.Component {
    componentDidMount() {
        mouse = new THREE.Vector2();

        let texture1 = new THREE.TextureLoader().load('landing/rejig1.png');
        let texture2 = new THREE.TextureLoader().load('landing/rejig2.png');
        let texture3 = new THREE.TextureLoader().load('landing/rejig3.png');
        let texture4 = new THREE.TextureLoader().load('landing/rejig4.png');

        let texture5 = new THREE.TextureLoader().load('landing/strong1.png');
        let texture6 = new THREE.TextureLoader().load('landing/strong2.png');
        let texture7 = new THREE.TextureLoader().load('landing/strong3.png');
        let texture8 = new THREE.TextureLoader().load('landing/strong4.png');

        let texture9 = new THREE.TextureLoader().load('landing/nudesign1.jpg');
        let texture10 = new THREE.TextureLoader().load('landing/nudesign2.jpg');
        let texture11 = new THREE.TextureLoader().load('landing/nudesign3.png');
        let texture12 = new THREE.TextureLoader().load('landing/nudesign4.png');

        let texture13 = new THREE.TextureLoader().load('landing/fluid1.png');
        let texture14 = new THREE.TextureLoader().load('landing/fluid2.png');
        let texture15 = new THREE.TextureLoader().load('landing/fluid3.png');
        let texture16 = new THREE.TextureLoader().load('landing/fluid4.jpg');

        let texture17 = new THREE.TextureLoader().load('landing/city1.png');
        let texture18 = new THREE.TextureLoader().load('landing/city2.png');
        let texture19 = new THREE.TextureLoader().load('landing/city3.png');
        let texture20 = new THREE.TextureLoader().load('landing/city4.png');

        function getTexture(i) {
            switch (i) {
                case 1:
                    return texture1;
                    break;
                case 2:
                    return texture2;
                    break;
                case 3:
                    return texture3;
                    break;
                case 4:
                    return texture4;
                    break;
                case 5:
                    return texture5;
                    break;
                case 6:
                    return texture6;
                    break;
                case 7:
                    return texture7;
                    break;
                case 8:
                    return texture8;
                    break;
                case 9:
                    return texture9;
                    break;
                case 10:
                    return texture10;
                    break;
                case 11:
                    return texture11;
                    break;
                case 12:
                    return texture12;
                    break;
                case 13:
                    return texture13;
                    break;
                case 14:
                    return texture14;
                    break;
                case 15:
                    return texture15;
                    break;
                case 16:
                    return texture16;
                    break;
                case 17:
                    return texture17;
                    break;
                case 18:
                    return texture18;
                    break;
                case 19:
                    return texture19;
                    break;
                case 20:
                    return texture20;
                    break;
            }
        }

        let radius = 250, theta = 1, theta2 = 1;

        let scene = new THREE.Scene();
        scene.background = new THREE.Color( 0xffffff );


        this.camera = new THREE.PerspectiveCamera(70, this.mount.offsetWidth/this.mount.offsetHeight, 1, 10000);

        this.controls = new DeviceOrientationControls(this.camera);

        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight)
        this.mount.appendChild(this.renderer.domElement)

        const light = new THREE.AmbientLight(0xffffff); // soft white light
        scene.add(light);

        let geometry1 = new THREE.PlaneGeometry(64, 64);

        let j = 1;
        for (let i = 0; i < 200; i++) {
            let material1 = new THREE.MeshLambertMaterial({
                map: getTexture(j),
                transparent: true,
                side: THREE.DoubleSide,
                refractionRatio: 0
            });

            let object = new THREE.Mesh(geometry1, material1);

            object.position.x = Math.random() * 800 - 400;
            object.position.y = Math.random() * 300 - 200;
            object.position.z = Math.random() * 800 - 400;

            scene.add(object);
            if (j === 20) {
                j = 1;
            } else {
                j++;
            }
        }

        this.animate = function () {
            requestAnimationFrame(this.animate.bind(this))

            theta += 0.05 + mouse.x;
            theta2 += 0.05 + mouse.y;

            this.camera.position.x = radius * Math.sin(THREE.MathUtils.degToRad(theta));
            this.camera.position.y = radius * Math.sin(THREE.MathUtils.degToRad(theta2));
            this.camera.position.z = radius * Math.cos(THREE.MathUtils.degToRad(theta));
            this.camera.lookAt(scene.position);

            this.camera.updateMatrixWorld();

            this.renderer.render(scene, this.camera)
        }

        this.animate()

        window.addEventListener('resize', this.onWindowResize.bind(this), false)

        document.addEventListener('mousemove', this.onDocumentMouseMove.bind(this), false);

    }

    onWindowResize() {
        if (this.mount) {
            this.camera.aspect = this.mount.offsetWidth / this.mount.offsetHeight
            this.camera.updateProjectionMatrix()
            this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight)
        }
    }

    onDocumentMouseMove(event) {

        event.preventDefault();

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    render() {
        return (
            <div ref={ref => (this.mount = ref)} style={{ width: `100%`, height: `100%` }}></div>
        )
    }
}

export default Scene
