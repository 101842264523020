import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Projects from "../components/Projects";
import Scene from "../components/Scene";

const IndexPage = ({location}) => {
    console.log(location);
    return (
        <>
            <Layout landing={true} active={"home"} location={location}>
                <SEO></SEO>
                <div className="landing__outer">
                    <div className="canvas__container animatedcanvas" id={"canvas-wrapper"}>
                        <div id="canvas-container">
                            <Scene></Scene>
                        </div>
                    </div>
                </div>
                <div id="mobile-button" style={{display: "none"}}>
                    <button id="startButton">Open Portfolio</button>
                </div>
                <Projects></Projects>
            </Layout>
        </>
    )
}

export default IndexPage

//<script src="../static/landing.js" type="module"></script>
//            <script src="landing.js" type="module"></script>
