import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Vimeo from "@u-wave/react-vimeo";

const Project = ({project}) => {

    return (
        <>
            <div className={project.featured ? "project__outer featured" : "project__outer"}>
                <div className="project__inner">
                    <div className="header">
                        <p className="year ">{project.year}</p>
                        <p className="location ">{project.location}</p>
                    </div>
                    <div className="center">
                        <h3 className="title uppercase">{project.title}</h3>
                        {project.featured ?
                            <div className={"feature"}>
                                <iframe src={"https://player.vimeo.com/video/" + project.video + "?h=aa0c427a08&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1;loop=1;muted=1;background=1;"} width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; muted;" allowFullScreen title={project.title}></iframe>
                            </div>
                        :
                            <></>
                        }
                        <p className="description">{project.description}</p>
                    </div>
                    <div className="bottom">
                        <div className="tags">
                            {project.tags.map(el => {
                                return <div className={"tag " + el}>{el}</div>
                            })}
                        </div>
                        <a href={"https://" + project.website} target={"blank"} className={"uppercase underline hover-moveup"}>{project.website}</a>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Project

/*
                                <img src={"../../" + project.image} alt={project.title} placeholder="blurred"></img>

 */
